import React, { Component } from 'react';
import Slider from "react-slick";
import Schedule from './../schedule/Schedule';

class Results extends Component {

    optimalSlidesToShow = (maxSlides) =>{
        return (maxSlides > 5 ? 5 : maxSlides);
    } 
    getCriteriaText(prefix){
        const criteria = this.props.state.criteria;

        return (
            <div className="criteria">
                {prefix}&nbsp;
                <i><strong>{criteria.fromStation.label} ({criteria.fromStation.cityName})</strong></i>&nbsp; 
                to <i><strong>{criteria.toStation.label} ({criteria.toStation.cityName})</strong></i>&nbsp;
                during <i><strong>{criteria.timeOfService.label}</strong></i>&nbsp;
                on <i><strong>{criteria.dayOfService.label}</strong></i>.
            </div>
        );
    }

    render() {
        const slidesToShow = this.optimalSlidesToShow(this.props.state.schedules.length);

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToScroll: 5,
            initialSlide: 0,
            variableWidth:false,
            swipeToSlide: true,
            vertical: true,
            verticalSwiping: true,
            slidesToShow: slidesToShow, 
            responsive: []
        };
  
        return (
            <div className="container-fluid search-results">
                {this.props.state.schedules.length > 0 &&
                    <div className="container results-container">
                       {this.getCriteriaText("Displaying schedules from")}
                        <Slider {...settings}>
                            {this.props.state.schedules.map(schedule =>
                                <Schedule key={schedule.startTime} {...schedule} />
                            )}
                        </Slider>
                    </div>
                }
                {this.props.state.schedules.length < 1 &&
                    <div className="no-results">
                        <span>There are no schedules available for your chosen stations on the selected day and time.</span>
                    </div>
                }
            </div>
        );
    }
}
export default Results;