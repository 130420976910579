import React, { Component } from 'react';

import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import { Link } from 'react-router-dom'

const styles = {
    navBar: {
        'top': AppBar.height
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12
    }
};



class Header extends Component {
    constructor() {
        super();
        this.state = { open: false };
    }
    handleToggle = (e) =>  { 
        let newState = !this.state.open;   
        this.setState({ open:  newState});
    }
    closeMenu = (e) =>{
        this.setState({ open: false});
    }
    render() {
        const { classes } = this.props;
        const modalProps = {
            onBackdropClick : this.closeMenu
        };
        const headerLabel = "NJ to NYC - Commuter Companion";
        return (
            <div>
                <AppBar position="fixed" className="App-header">
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                            <MenuIcon onClick={this.handleToggle} />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            {headerLabel}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    open={this.state.open}
                    className='nj2nyc-drawer'
                    ModalProps={modalProps}
                    width={250}>

                    <MenuList className="nav-drawer">
                        <Link to="/">
                            <MenuItem onClick={this.handleToggle}>Schedules</MenuItem>
                        </Link>
                        <Link to="/support">
                            <MenuItem onClick={this.handleToggle}>Support</MenuItem>
                        </Link>
                    </MenuList>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(Header);