import React, { Component } from 'react';

import {MuiThemeProvider} from '@material-ui/core/styles';

import './App.css';

import "./../../style/slick.min.css";
import "./../../style/slick-theme.min.css";

import AppUtil from './../../util/AppUtil';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import Splash from './../splash/Splash';
import Search from './../search/Search';
import Results from './../results/Results';
import SearchService from './../../util/SearchServiceV2';

class App extends Component {

  state = {
    fromStation : null,
    toStation : null,
    dayOfService : null,
    timeOfService : null,
    schedules: []
  }
  constructor(props){
    super(props);
    Object.assign(this.state,{nj2nyc : this.props.nj2nyc});
  }

  componentDidMount(){
    Object.assign(this.state,SearchService.getDefaultCriteria());
    this.handleSearch();
  }

  handleChange = (targetName) => (selectedOption) => {
    let updatedState = {};
    updatedState[targetName] = selectedOption;
    this.setState(updatedState);  
  }
  
  handleSearch = (e) => {
    
    e && e.preventDefault();

    const fromStationCode = this.state.fromStation.value;
    const toStationCode = this.state.toStation.value;
    const dayOfService = this.state.dayOfService.value;
    const timeOfService = this.state.timeOfService.value;

    const results = SearchService.getSchedulesForCritera(fromStationCode,toStationCode,dayOfService,timeOfService);
    
    const criteriaStr = JSON.stringify({
      fromStation  : this.state.fromStation,
      toStation : this.state.toStation,
      dayOfService : this.state.dayOfService,
      timeOfService : this.state.timeOfService
    });

    const criteria = JSON.parse(criteriaStr);

    this.setState({ schedules: results, criteria : criteria });
  }

  render() {

    return (
      <MuiThemeProvider theme={AppUtil.getDefaultTheme()}>
        <div className="App">
          <Header/>
          <main className="App-main">
            <Splash {...this}/>
            <Search {...this} />
            <Results {...this} />
          </main>
        <Footer/>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
