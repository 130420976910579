import React, { Component } from 'react';
import {MuiThemeProvider } from '@material-ui/core/styles';

import './Support.css';
import './../app/App.css';

import AppUtil from './../../util/AppUtil';
import Header from './../header/Header';
import Footer from './../footer/Footer';


class Support extends Component {

    contact(){
        return (
            <section>
                <div className="row">
                <div className="col-xs-12 quest">
                    <div className="h6">How can you contact us?</div>
                </div>
            </div>
            <div className="row">
                <div className="ans">
                    <div className="row"> 
                        <div className="col-xs-12">
                        This is not an official site of any commuter agencies. However, every attempt has been made to provide accurate results based on publicly available data. 
                         Please feel free to tell us with email to contact.nj2nyc@gmail.com on how we can improve. 
                        </div>
                    </div>
                </div>
            </div>
            </section>
        );

    }
    currentData() {
        return <section>
            <div className="row">
                <div className="col-xs-12 quest">
                    <div className="h6">What schedules are available now?</div>
                </div>
            </div>
            <div className="row">
                <div className="ans">
                    <div className="row"> 
                        <div className="col-xs-12">
                            Currently showing latest (April 2019) CoachUSA/Suburban/Academy bus schedules from Central Jersey to 
                    &nbsp;<a href="/route300_apr2019.pdf" target="_blank" rel="noopener noreferrer">New York Midtown</a>&nbsp; and 
                    &nbsp;<a href="/route600_apr2019.pdf" target="_blank" rel="noopener noreferrer">Downtown</a>.&nbsp; Expanding soon to other routes.
                        </div>
                    </div>
                </div>
            </div>
        </section>

    }

    gateInfo() {
        return <section>
            <div className="row">
                <div className="col-xs-12 quest">
                    <div className="h6">What are the Port Authority Terminal Gates?</div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 ans">
                    <div className="row"><div className="col-xs-12">Gate 415 - Hightstown Local </div></div>
                    <div className="row"><div className="col-xs-12">Gate 416 - Twin Rivers Express </div></div>
                    <div className="row"><div className="col-xs-12">Gate 417 - Princeton NB Local</div></div>
                    <div className="row"><div className="col-xs-12">Gate 418 - New Brunswick JFK Local</div></div>
                    <div className="row"><div className="col-xs-12">Gate 419 - Princeton Express</div></div>
                    <div className="row"><div className="col-xs-12">Gate 420 - Transportation Center </div></div>
                </div>
            </div>
        </section>

    }

    ticketInfo() {
        return <section>
            <div className="row">
                <div className="quest">
                    <div className="h6">Where can I buy the Tickets?</div>
                </div>
            </div>
            <div className="row">
                <div className="ans">
                    <div className="row"><div className="col-xs-12">8A Park &amp; Ride </div></div>
                    <div className="row"><div className="col-xs-12">Shell Gas Station (Cash Only)</div></div>
                    <div className="row"><div className="col-xs-12">Twin Rivers Deli (Cash Only)</div></div>
                </div>
            </div>
        </section>

    }

    fareInfo() {
        return <section>
            <div className="row">
                <div className="quest">
                    <div className="h6">What is the fare from 8A Park &amp; Ride to NYC?</div>
                </div>
            </div>
            <div className="row">
                <div className="ans">
                    <div className="row">
                        <div className="col-xs-4">Half Fare : </div>
                        <div className="col-xs-8">&nbsp;<strong>$9.50</strong> (Valid Until Used, Non Refundable)</div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">One Way : </div>
                        <div className="col-xs-8">&nbsp;<strong>$19 </strong> (Valid Until Used, Non Refundable) </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">Round Trip : </div>
                        <div className="col-xs-8">&nbsp;<strong>$38 </strong>(Valid Until Used, Non Refundable) </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">10 Trip :</div>
                        <div className="col-xs-8">&nbsp;<strong>$135 </strong>(Valid for 20 days, Refundable)</div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">20 Trip  :</div>
                        <div className="col-xs-8">&nbsp; <strong>$270 </strong>(Valid for 40 days, Refundable)</div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">Monthly Pass :</div>
                        <div className="col-xs-8">&nbsp;<strong>$480 </strong>(Non Refundable)</div>
                    </div>
                </div>
            </div>
        </section>

    }


    render() {
        return (
            <MuiThemeProvider theme={AppUtil.getDefaultTheme()}>
                <div className="Support">
                    <Header />
                    <main className="Support-main">
                        {this.currentData()}
                        {this.fareInfo()}
                        {this.gateInfo()}
                        {this.contact()}
                    </main>
                    <Footer />
                </div>
            </MuiThemeProvider>

        );
    }
}

export default Support;