import {createMuiTheme} from '@material-ui/core/styles';

export default {

    getDefaultTheme(){
        return createMuiTheme({
            typography: {
            useNextVariants: true
            },
            palette: {
                primary: {
                    main: '#282c34'
                }
            }
        });
    },
    //Sets cookie for 90 days 
    // Format - default=from,to,dos,tos
    setDefaultCriteriaToCookie(from,to,dos,tos){
        let cookieName = 'nj2nyc';
        let cookieValue = from +','+to+','+dos+','+tos;
        let date = new Date();
        date.setTime(date.getTime() + (90*24*60*60*1000));
        let expires = "; expires=" + date.toUTCString();

        let cookie = cookieName + "=" + cookieValue + expires + "; path=/";
        document.cookie = cookie;
        //console.log('cookie set  '+cookie);
    },
    getDefaultCriteriaFromCookie(){
        let cookieName ='nj2nyc=';
        let cookieValue = '';
        let cookieDefault = {
            from : '',
            to : '',
            dos : '',
            tos : ''
        };
        // nj2nyc=ABBTNDR,PABTALL,MTH,MORNING; expires=82938409; path=/
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++){
            var c = ca[i];
            while(c.charAt(0) === ' '){ 
                c = c.substring(1,c.length);
            }

            if(c.indexOf(cookieName) === 0){
                cookieValue = c.substring(cookieName.length,c.length);
            }
        }
        let cValues = cookieValue.split(',');
         //override values if they are available; 
        if (cValues && cValues.length === 4){
            cookieDefault.from = cValues[0];
            cookieDefault.to = cValues[1];
            cookieDefault.dos = cValues[2];
            cookieDefault.tos = cValues[3];
        }
        //console.log('cookieDefault '+cookieValue);

        return cookieDefault;
    }
};