import firebase from 'firebase/app';

export const init = () => {
    var config = {
        apiKey: "AIzaSyBR5IHdbGXHSAaf6OOBxpdt_rx4_lUpX8A",
        authDomain: "monroe2nyc.firebaseapp.com",
        databaseURL: "https://monroe2nyc.firebaseio.com",
        projectId: "monroe2nyc",
        storageBucket: "monroe2nyc.appspot.com",
        messagingSenderId: "183867112725"
    };
    firebase.initializeApp(config);
}