import React, { Component } from 'react';

class RouteAdmin extends Component {

    render() {
        return (
            <div>
                <h3>Route Admin - Add a route </h3>
            </div>
        );
    }
}

export default RouteAdmin;