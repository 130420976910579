import React, { Component } from 'react';
import Select from 'react-select';
import TimeInput from 'material-ui-time-picker'
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import './TripAdmin.css';
import AdminService from './../../util/AdminService';

class TripAdmin extends Component {
    state = {
        stationOrder: 'NJ',
        stations:  AdminService.getStationModelAsMapForRoute("LINE300_EWR_NYC_EXIT8"),
        formControls: {
            route: {
                name: 'route',
                label: 'Route',
                value: AdminService.getRouteModel().filter((s) => {
                    return (s.value === "LINE300_EWR_NYC_EXIT8");
                })[0],
                options: AdminService.getRouteModel()
            },
            dayOfService: {
                name: 'dayOfService',
                label: 'Days of Service',
                value: AdminService.getDaysOfServiceModel().filter((s) => {
                    return (s.value === "MTH");
                })[0],
                options: AdminService.getDaysOfServiceModel()
            },
            fromStationCode: {
                name: 'fromStationCode',
                label: 'Origin Station',
                value: AdminService.getStationModel().filter((s) => {
                    return (s.value === "QUAD4LL");
                })[0],
                options: AdminService.getStationModel()
            },
            startTime: {
                name: 'startTime',
                label: 'Trip Origin Time',
                value: new Date(2019, 1, 1, 0, 0, 0, 0)
            },
            endTime: {
                name: 'endTime',
                label: 'Trip End Time',
                value: new Date(2019, 1, 1, 0, 0, 0, 0)
            },
            toStationCode: {
                name: 'toStationCode',
                label: 'Destination Station',
                value: AdminService.getStationModel().filter((s) => {
                    return (s.value === "PABT");
                })[0],
                options: AdminService.getStationModel()
            },
            mode: {
                name: 'mode',
                label: 'Mode of Transportation',
                value: AdminService.getModeModel().filter((s) => {
                    return (s.value === "BUS");
                })[0],
                options: AdminService.getModeModel()
            },
            operatorCode: {
                name: 'operatorCode',
                label: 'Trip Operator',
                value: AdminService.getOperatorModel().filter((s) => {
                    return (s.value === "SUBURBAN");
                })[0],
                options: AdminService.getOperatorModel()
            },
            tripName: {
                label: 'Trip Name (assigned)',
                name: 'tripName',
                value: 'To be generated'
            },
            vehicleNumber: {
                label: 'Vehicle Numer',
                name: 'vehicleNumber',
                value: 'VIN'
            },
            stops: {}
        }
    }

    constructor(props){
        super(props);
        this.defaultState = cloneDeep(this.state);
    }

    handleStopsChange = (operation, targetName) => (targetValue) => {
        let stationsMap =  this.state.stations;
        let updatedState = { formControls: {}, stations: {} };
        let stationToUpdate = stationsMap[targetName];
        let value = targetValue;

        if (operation === 'stop') {
            value = targetValue && targetValue.target && targetValue.target.checked;
            stationToUpdate.stop = value;
        } else if (operation === 'aTime') {
            stationToUpdate.aTime = value;
        }

        stationsMap[targetName] = stationToUpdate;

        Object.assign(updatedState, { formControls: this.state.formControls, stations: stationsMap });
        this.setState(updatedState);

    }
    getStopsForRouteAndDirection(routeName,direction){
        let newStations = {};

        if (direction === 'NY') {
            newStations = AdminService.getReversedStationModelAsMapForRoute(routeName);
        } else {
            newStations = AdminService.getStationModelAsMapForRoute(routeName);
        }
        return newStations;
    }
    handleChange = (targetName) => (targetValue) => {
        if (targetValue) {
            let name = targetName;
            let value = targetValue.target ? targetValue.target.value : targetValue;
            let updatedState = {};

            Object.assign(updatedState, this.state);
            
            if(targetName === 'route'){
                let currentDirection = this.state.stationOrder;
                let newStations = this.getStopsForRouteAndDirection(value.value,currentDirection);
                updatedState.stations = newStations;
            }
    
            updatedState.formControls[name].value = value;
            this.setState(updatedState);
        }

    }
    convertToMinute(inputTime) {
        let dateObj = moment(inputTime, 'HH:mm');
        let timeInMinutes = dateObj.hours() * 60 + dateObj.minutes();
        return timeInMinutes;
    }

    generateTripName(trip) {
        return (trip['route'] + '_' + trip['operatorCode'] + '_' +
            trip['mode'] + '_' + trip['vehicleNumber'] + '_' +
            trip['fromStationCode'] + '_' + trip['toStationCode'] + '_' +
            trip['dayOfService'] + '_' + trip['startTime'] + '_' + trip['endTime']
        );
    }
    reverseStationOrder = (e) => {
       
        let newOrder = this.state.stationOrder === 'NJ' ? 'NY' : 'NJ';
        const selectedRoute = this.state.formControls.route.value.value;

        let newStations = this.getStopsForRouteAndDirection(selectedRoute,newOrder);
       
        let updatedState = {};
        Object.assign(updatedState, this.state);
        updatedState.stationOrder = newOrder;
        updatedState.stations = newStations;

        this.setState(updatedState);
    }
    handleSubmit = (e) => {

        let self = this;
        
        let finalState = { stops: {} };
        let stationsMap = self.state.stations;

        finalState['dayOfService'] = self.state.formControls['dayOfService'].value.value;
        finalState['mode'] = self.state.formControls['mode'].value.value;
        finalState['vehicleNumber'] = self.state.formControls['vehicleNumber'].value;
        finalState['route'] = self.state.formControls['route'].value.value;
        finalState['fromStationCode'] = self.state.formControls['fromStationCode'].value.value;
        finalState['toStationCode'] = self.state.formControls['toStationCode'].value.value;
        finalState['operatorCode'] = self.state.formControls['operatorCode'].value.value;
        finalState['startTime'] = self.convertToMinute(self.state.formControls['startTime'].value);
        finalState['endTime'] = self.convertToMinute(self.state.formControls['endTime'].value);
        finalState['tripName'] = self.generateTripName(finalState);

        for (let s in stationsMap) {
            let station = stationsMap[s];
            if (station.stop) {
                let t = self.convertToMinute(station.aTime);
                finalState.stops[station.value] = {
                    "aTime": t,
                    "dTime": t,
                    "sequence": station.sequence,
                    "stop": station.stop
                };
            }
        }

        let updatedState = {};
        Object.assign(updatedState, self.state);
        updatedState.formControls['tripName'].value = finalState['tripName'];
        this.setState(updatedState);

        console.log('Trip generated successfully and trip name is assigned - '+finalState['tripName']);
        console.log(JSON.stringify(finalState));
        e && e.preventDefault();
    }

    handleCancel = (e) => {

        let self = this;
        let resetState = cloneDeep(self.defaultState);
        self.setState(resetState);
        e && e.preventDefault();
    }
    getStopsForm() {
        let stationsRow = [];
        const selectedRoute = this.state.formControls.route.value.value;

        if(selectedRoute) {
            //Route selection is mandatory to display the list of stops 

            let stationsMap =   this.state.stations;
        
            let self = this;

            const StopRow = (station) => {
                return {
                    render() {
                        return (
                            <div className="row stops-row">
                                <div className="col-xs-8  name-col">
                                    {station.label}
                                </div>
                                <div className="col-xs-4  stop-col">
                                    <input
                                        name={station.value + '.stop'}
                                        type="checkbox"
                                        checked={station.stop}
                                        onChange={self.handleStopsChange('stop', station.value)} />
                                </div>
                                <div className="col-xs-8 stop-time-col">
                                    <TimeInput
                                        mode='12h'
                                        value={station.aTime}
                                        onChange={self.handleStopsChange('aTime', station.value)}
                                    />
                                </div>
                            </div>
                        );
                    }
                }
            };

            for (let s in stationsMap) {
                let station = stationsMap[s];
                if (!station.aliasFor) {
                    stationsRow.push(<StopRow key={station.value} {...station} />);
                }
            }
        }else{
            // Info message
            stationsRow.push(
                <div className="row stops-row">
                    <h4>Please first select route to get list of stops.</h4>
                </div>
            );
        }

        return stationsRow;
    }

    render() {

        return (

            <div className="container-fluid trip-container">
                <form className="form-inline">
                    <div className="row">
                        <h4> Trip Main Details </h4>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.tripName.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                {this.state.formControls.tripName.value}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.route.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <Select
                                    className="form-select-station"
                                    name={this.state.formControls.route.name}
                                    value={this.state.formControls.route.value}
                                    onChange={this.handleChange(this.state.formControls.route.name)}
                                    options={this.state.formControls.route.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col  center-block">
                                {this.state.formControls.mode.label}
                            </div>
                            <div className="col-xs-8  value-col center-block">
                                <Select
                                    className="form-select-service"
                                    name={this.state.formControls.mode.name}
                                    value={this.state.formControls.mode.value}
                                    onChange={this.handleChange(this.state.formControls.mode.name)}
                                    options={this.state.formControls.mode.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col  center-block">
                                {this.state.formControls.operatorCode.label}
                            </div>
                            <div className="col-xs-8  value-col center-block">
                                <Select
                                    className="form-select-service"
                                    name={this.state.formControls.operatorCode.name}
                                    value={this.state.formControls.operatorCode.value}
                                    onChange={this.handleChange(this.state.formControls.operatorCode.name)}
                                    options={this.state.formControls.operatorCode.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.vehicleNumber.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <input
                                    type="text"
                                    name={this.state.formControls.vehicleNumber.name}
                                    value={this.state.formControls.vehicleNumber.value}
                                    onChange={this.handleChange(this.state.formControls.vehicleNumber.name)}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.dayOfService.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <Select
                                    className="form-select-service"
                                    name={this.state.formControls.dayOfService.name}
                                    value={this.state.formControls.dayOfService.value}
                                    onChange={this.handleChange(this.state.formControls.dayOfService.name)}
                                    options={this.state.formControls.dayOfService.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.fromStationCode.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <Select
                                    className="form-select-station"
                                    name={this.state.formControls.fromStationCode.name}
                                    value={this.state.formControls.fromStationCode.value}
                                    onChange={this.handleChange(this.state.formControls.fromStationCode.name)}
                                    options={this.state.formControls.fromStationCode.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.startTime.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <TimeInput
                                    mode='12h'
                                    value={this.state.formControls.startTime.value}
                                    onChange={this.handleChange(this.state.formControls.startTime.name)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.toStationCode.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <Select
                                    className="form-select-station"
                                    name={this.state.formControls.toStationCode.name}
                                    value={this.state.formControls.toStationCode.value}
                                    onChange={this.handleChange(this.state.formControls.toStationCode.name)}
                                    options={this.state.formControls.toStationCode.options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 label-col center-block">
                                {this.state.formControls.endTime.label}
                            </div>
                            <div className="col-xs-8 value-col center-block">
                                <TimeInput
                                    mode='12h'
                                    value={this.state.formControls.endTime.value}
                                    onChange={this.handleChange(this.state.formControls.endTime.name)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-8 label-col center-block">
                            <button type="button" onClick={this.reverseStationOrder} className="btn btn-primary">{'From '+this.state.stationOrder}</button>
                        </div>
                        <div className="col-xs-4 value-col  center-block">
                            <h4> Stations </h4>
                        </div>
                    </div>
                    <div className="row stops-row">
                        <div className="col-xs-8 name-col">
                            <h5>Station</h5>
                        </div>
                        <div className="col-xs-4 stop-col">
                            <h5>Does it Stop ?</h5>
                        </div>
                        <div className="col-xs-4 stop-time-col">
                            <h5>Stop Time</h5>
                        </div>
                    </div>

                    {this.getStopsForm()}

                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-4 button-col cancel-button">
                                <button type="cancel" onClick={this.handleCancel} className="btn btn-primary">Cancel</button>
                            </div>
                            <div className="col-xs-4 button-col submit-button">
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
}

export default TripAdmin;