import moment from 'moment';
import AppUtil from './AppUtil';
import ScheduleModel from './ScheduleModelV2';
import ModeModel from './../data/mode.json';
import StationsModel from './../data/stations.json';
import RoutesModel from './../data/routes.json';
import DaysOfServiceModel from './../data/daysOfService.json';
import TimeOfServiceModel from './../data/timeOfService.json';

export default {
    getModeModel() {
        return ModeModel;
    },
    getTimeOfServiceModel() {
        return TimeOfServiceModel;
    },
    getDaysOfServiceModel() {
        return DaysOfServiceModel;
    },
    getStationModel() {
        return StationsModel;
    },

    getRoutesModelAsMap(){
        let routesMap = {};
        const routes = RoutesModel;
        routes.map((r) => {
            routesMap[r.value] = r;
            return true;
        });
        return routesMap;
    },
    getTripSchedulesModel() {
        let ts = [];
        const s = ScheduleModel;
        for (let sm in s) {
            for (let i = 0, len = s[sm].length; i <= len; ++i) {
                ts.push(s[sm][i]);
            }
        }
        return ts;
    },
       
    getDefaultCriteria() {
        //set initial state based on location, day, time preferences 
        const StationsModel = this.getStationModel();
        const DaysOfServiceModel = this.getDaysOfServiceModel();
        const TimeOfServiceModel = this.getTimeOfServiceModel();
        let defaultValues = { 
            from : "ABBTNDR",
            to : "PABTALL",
            dos : "MTH",
            tos : "MORNING"
        };

        //Get from cookie 
        //let cookieDefault = AppUtil.getDefaultCriteriaFromCookie();
        //todo : validate the values before setting 
        // Object.keys(defaultValues).forEach(function(key){
        //     let cookieVal = cookieDefault[key];
        //     if(cookieVal && cookieVal.length > 0){
        //          defaultValues[key] =  cookieVal;
        //     }
        // });
  
        let initialState = {
            fromStation: StationsModel.filter((s) => {
                return (s.value === defaultValues.from);
            })[0],
            toStation: StationsModel.filter((s) => {
                return (s.value === defaultValues.to);
            })[0],
            dayOfService: DaysOfServiceModel.filter((s) => {
                return (s.value === defaultValues.dos);
            })[0],
            timeOfService: TimeOfServiceModel.filter((t) => {
                return (t.value === defaultValues.tos);
            })[0]
        };
        return initialState;
    },
    getCompatibleStations(s) {
        if (s === "PABTALL") {
            return ["PABT", "42S8A"];
        }
        return [s];
    },
   
    isTripMatchTime(scheduleTime, timeOfService) {
        // using moment compare the time; 
        let startTime, endTime,
            tripTime = moment(scheduleTime, 'HH:mm');
        switch (timeOfService) {
            case 'MORNING':
                //startTime after 2 am and before 10am
                startTime = moment('02:00', 'HH:mm');
                endTime = moment('10:00', 'HH:mm');
                return tripTime.isAfter(startTime) && tripTime.isBefore(endTime);
            case 'MIDDAY':
                //startTime after 10am and before 3pm
                startTime = moment('09:59', 'HH:mm');
                endTime = moment('15:00', 'HH:mm');
                return tripTime.isAfter(startTime) && tripTime.isBefore(endTime);
            case 'EVENING':
                //startTime after 3pm, before 8 pm
                startTime = moment('14:59', 'HH:mm');
                endTime = moment('20:00', 'HH:mm');
                return tripTime.isAfter(startTime) && tripTime.isBefore(endTime);
            case 'NIGHT':
                //startTime after 8pm
                startTime = moment('19:59', 'HH:mm');
                return tripTime.isAfter(startTime);
            default:
                //Anytime 
                return true;
        }
    },
    isCorrectDirection(schedule) {

        if (schedule['fromStationCode'] && schedule['toStationCode']) {

            let startTime = moment(schedule['startTime'], 'HH:mm');
            let stopTime = moment(schedule['stopTime'], 'HH:mm');
            return startTime.isBefore(stopTime) || schedule['fromDay'] <  schedule['toDay'];
        }
        return false;
    },

    getSchedulesForCritera(fromStationCode, toStationCode, dayOfService, timeOfService) {
       
        let _that = this;
        const Trips = ScheduleModel;
        let results = [], fromStops = [], toStops = [];

        AppUtil.setDefaultCriteriaToCookie(fromStationCode,toStationCode,dayOfService,timeOfService);

        fromStops = _that.getCompatibleStations(fromStationCode);
        toStops = _that.getCompatibleStations(toStationCode);

        let routesMap = _that.getRoutesModelAsMap();

        for (let t in Trips) {

            if (Trips[t].dayOfService === dayOfService) {

                let matchedSchedule = { 'routeDetail' : routesMap[Trips[t].route], 'express' : false };

                let filteredSchedules = Trips[t].stops;

                for (let s in filteredSchedules) {

                    let currentStop = filteredSchedules[s];

                    if (currentStop.stop){

                        if (fromStops.includes(s)) {
                            matchedSchedule['fromStationCode'] = s;
                            matchedSchedule['startTime'] = _that.formatTimeInMinutes(currentStop.aTime);
                            matchedSchedule['fromSequence'] = currentStop.sequence
                            matchedSchedule['fromDay'] = currentStop.day || 0; 
                        }

                        if (toStops.includes(s)) {
                            matchedSchedule['toStationCode'] = s;
                            matchedSchedule['stopTime'] = _that.formatTimeInMinutes(currentStop.aTime);
                            matchedSchedule['toSequence'] = currentStop.sequence
                            matchedSchedule['toDay'] = currentStop.day || 0; 
                        }
                    }
                }

                if (_that.isCorrectDirection(matchedSchedule) &&
                    _that.isTripMatchTime(matchedSchedule['startTime'], timeOfService)) {
                    
                    results.push(matchedSchedule);
                }
            }

        }
        
        //sort the results based on start time 
        results = results.sort((a,b) => {
            let aTime = moment(a['startTime'], 'HH:mm');
            let bTime = moment(b['startTime'], 'HH:mm');
           
            return aTime.isBefore(bTime) ? -1 : 1;
        });

        return results;
    },
    formatTimeInMinutes : function(timeInMinutes){
        let minutes = timeInMinutes % 60;
        let hours = (timeInMinutes - minutes) / 60;
        
        minutes = minutes < 10 ? '0'+minutes : minutes;
        hours = hours < 10 ? '0'+hours : hours;

        let formattedTime = hours+':'+minutes;
       
        return formattedTime;
    }

};