import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from "react-router-dom";

import './style/bootstrap.min.css';
import './style/index.css';
import App from './component/app/App';
import Admin from './component/admin/Admin';
import Support from './component/support/Support';
import * as serviceWorker from './util/serviceWorker';

import {init as firebaseInit} from './firebase';


import ReactGA from 'react-ga';

/** Define Global Properties */
var nj2nyc = {};
const ua = navigator.userAgent;
const Device = {
    iPad: /iPad/.test(ua),
    iPhone: /iPhone/.test(ua),
    Android: /Android/.test(ua),
    isMobileApp : /nj2nycMobileApp/.test(window.location.href)
};
nj2nyc.Device = Device;

const NJ2NYCApp = ()=>{
    return <App {...{nj2nyc}}/>;
}

ReactDOM.render(
    <BrowserRouter>
        <div>
            <Route exact path="/" component={NJ2NYCApp} />
            <Route path="/admin" component={Admin} />
            <Route path="/support" component={Support} />
        </div>
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

//Initialize Firebase 
firebaseInit();

//Google Analytics 
//todo : better handle it through environment variables 
if(window.location.hostname.indexOf('localhost') < 0){
    ReactGA.initialize('UA-131525542-1');
    ReactGA.pageview('/home');
}

