import React, { Component } from 'react';
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import {MuiThemeProvider} from '@material-ui/core/styles';

import  './Admin.css';
import './../app/App.css';

import AppUtil from './../../util/AppUtil';
import Header from './../header/Header';
import Footer from './../footer/Footer';

import RouteAdmin from './RouteAdmin';
import TripAdmin from './TripAdmin';

class Admin extends Component {

    getActiveStyle(){
        return {
            fontWeight: "bold",
            color: "green"
        };
    }

    render() {
        return (
            <MuiThemeProvider theme={AppUtil.getDefaultTheme()}>
                <div className="Admin">
                    <Header />
                    <main className="Admin-main">
                        <BrowserRouter>
                            <div>
                                <NavLink className="admin-link app" to="/">App</NavLink>
                                <NavLink className="admin-link home" to="/admin">Admin</NavLink>
                                <NavLink className="admin-link" to="/admin/route" activeStyle={this.getActiveStyle()}>Add Route</NavLink>
                                <NavLink className="admin-link" to="/admin/trip" activeStyle={this.getActiveStyle()}>Add Trip</NavLink>
                                <Route path="/admin/route" component={RouteAdmin} />
                                <Route path="/admin/trip" component={TripAdmin} />
                            </div>
                        </BrowserRouter>
                    </main>
                    <Footer />
                </div>
            </MuiThemeProvider>

        );
    }
}

export default Admin;