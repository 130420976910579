import RouteModel from './../data/routes.json';
import OperatorModel from './../data/operators.json';
import ModeModel from './../data/mode.json';
import StationsModel from './../data/stations.json';
import DaysOfServiceModel from './../data/daysOfService.json';
import TimeOfServiceModel from './../data/timeOfService.json';

export default {
    
    getOperatorModel(){
        return OperatorModel;
    },
    getRouteModel(){
        return RouteModel;
    },
    getModeModel() {
        return ModeModel;
    },
    getDaysOfServiceModel() {
        return DaysOfServiceModel;
    },
    getStationModel() {
        return StationsModel;
    },
    getStationModelAsMap() {
        let stationsMap = {};
        const stations = StationsModel;
        stations.sort((a,b) =>{
            return a.sequence > b.sequence ? 1 : -1;
        })  
        stations.map((station)=>{
            Object.assign(station,{'aTime': new Date(2019,1,1,0,0,0,0),'stop':false});
            stationsMap[station.value] = station;
            return true;
        });
        return stationsMap;
    },
    getReversedStationModelAsMap() {
        let stationsMap = {};
        const stations = StationsModel;
        stations.sort((a,b) =>{
            return a.sequence < b.sequence ? 1 : -1;
        })   
        stations.map((station)=>{
            Object.assign(station,{'aTime': new Date(2019,1,1,0,0,0,0),'stop':false});
            stationsMap[station.value] = station;
            return true;
        });
        return stationsMap;
    },
    getStationModelAsMapForRoute(routeName) {
        let stationsMap = {};
        const route = RouteModel.filter((r) => {
            return r.value === routeName;
        })[0];
        const allStations = this.getStationModelAsMap();
        const routeStations = route.stops;
        
        routeStations.sort((a,b) =>{
            return a.sequence > b.sequence ? 1 : -1;
        });

        routeStations.map((station)=>{
            Object.assign(station,allStations[station.value]);
            stationsMap[station.value] = station;
            return true;
        });
        return stationsMap;
    },
    getReversedStationModelAsMapForRoute(routeName) {

        let stationsMap = {};
        const route = RouteModel.filter((r) => {
            return r.value === routeName;
        })[0];
        const allStations = this.getReversedStationModelAsMap();
        const routeStations = route.stops;
        
        routeStations.sort((a,b) =>{
            return a.sequence < b.sequence ? 1 : -1;
        });

        routeStations.map((station)=>{
            Object.assign(station,allStations[station.value]);
            stationsMap[station.value] = station;
            return true;
        });
        
        return stationsMap;
    },
    getTimeOfServiceModel() {
        return TimeOfServiceModel;
    }
};