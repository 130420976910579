import moment from 'moment';


import ScheduleModel from './ScheduleModel.js';
import ModeModel from './../data/mode.json';
import StationsModel from './../data/stations.json';
import DaysOfServiceModel from './../data/daysOfService.json';
import TimeOfServiceModel from './../data/timeOfService.json';
import NYTripsModel from './../data/schedules/ny/trips-ny.json';
import NJTripsModel from './../data/schedules/nj/trips-nj.json';

export default {
    getModeModel() {
        return ModeModel;
    },
    getTimeOfServiceModel() {
        return TimeOfServiceModel;
    },
    getTripsModel() {
        return NYTripsModel.concat(NJTripsModel);
    },
    getDaysOfServiceModel() {
        return DaysOfServiceModel;
    },
    getStationModel() {
        return StationsModel;
    },

    getTripSchedulesModel() {
        let ts = [];
        const s = ScheduleModel;
        for (let sm in s) {
            for (let i = 0, len = s[sm].length; i <= len; ++i) {
                ts.push(s[sm][i]);
            }
        }
        return ts;
    },
    getDefaultCriteria() {
        //set initial state based on location, day, time preferences 
        const StationsModel = this.getStationModel();
        const DaysOfServiceModel = this.getDaysOfServiceModel();
        const TimeOfServiceModel = this.getTimeOfServiceModel();
        //Todo ; based on moment default morning and evening as well as from and to
        //8APARK to PABT vice-versa 
        let initialState = {
            timeOfService: TimeOfServiceModel.filter((t) => {
                return (t.value === "ANYTIME");
            })[0],
            fromStation: StationsModel.filter((s) => {
                return (s.value === "ABBTNDR");
            })[0],
            toStation: StationsModel.filter((s) => {
                return (s.value === "PABTALL");
            })[0],
            dayOfService: DaysOfServiceModel.filter((s) => {
                return (s.value === "MTH");
            })[0],
            TripsModel: this.getTripsModel(),
            TripSchedulesModel: this.getTripSchedulesModel()
        };
        return initialState;
    },
    getCompatibleStations(s) {
        if (s === "PABTALL") {
            return ["PABT", "42S8A"];
        }
        return [s];
    },
    isTripMatchTime(scheduleTime, timeOfService) {
        // using moment compare the time; 
        let startTime, endTime,
            tripTime = moment(scheduleTime, 'HH:mm');
        switch (timeOfService) {
            case 'MORNING':
                //startTime before 10am
                endTime = moment('10:00', 'HH:mm');
                return tripTime.isBefore(endTime);
            case 'MIDDAY':
                //startTime after 10am and before 3pm
                startTime = moment('10:00', 'HH:mm');
                endTime = moment('15:00', 'HH:mm');
                return tripTime.isAfter(startTime) && tripTime.isBefore(endTime);
            case 'EVENING':
                //startTime after 3pm, before 8 pm
                startTime = moment('15:00', 'HH:mm');
                endTime = moment('20:00', 'HH:mm');
                return tripTime.isAfter(startTime) && tripTime.isBefore(endTime);
            case 'NIGHT':
                //startTime after 8pm
                startTime = moment('20:00', 'HH:mm');
                return tripTime.isAfter(startTime);
            default:
                //Anytime 
                return true;
        }
    },

    isCorrectDirection(schedule) {

        if (schedule['fromStationCode'] && schedule['toStationCode']) {

            let startTime = moment(schedule['startTime'], 'HH:mm');
            let stopTime = moment(schedule['stopTime'], 'HH:mm');

            return startTime.isBefore(stopTime) ? true : false;
        }
        return false;
    },

    getSchedulesForCritera(fromStationCode, toStationCode, dayOfService, timeOfService) {
        let _that = this;
        const Trips = _that.getTripsModel();
        const TripSchedulesModel = _that.getTripSchedulesModel();
        let results = [], fromStops = [], toStops = [];

        fromStops = _that.getCompatibleStations(fromStationCode);
        toStops = _that.getCompatibleStations(toStationCode);

        ;
        for (let t = 0; t < Trips.length; t++) {

            if (Trips[t].dayOfService.indexOf(dayOfService) > -1) {

                let matchedSchedule = { express: Trips[t].express };

                let filteredSchedules = TripSchedulesModel.filter((ts) => {
                    return (ts && (ts.tripId === Trips[t].tripId));
                });

                for (let s = 0; s < filteredSchedules.length; s++) {

                    let currentStop = filteredSchedules[s];

                    if (fromStops.includes(currentStop.stationCode)) {
                        matchedSchedule['fromStationCode'] = currentStop.stationCode;
                        matchedSchedule['startTime'] = currentStop.arrivalTime;
                        matchedSchedule['tripId'] = currentStop.tripId;
                    }

                    if (toStops.includes(currentStop.stationCode)) {
                        matchedSchedule['toStationCode'] = currentStop.stationCode;
                        matchedSchedule['stopTime'] = currentStop.arrivalTime;
                        matchedSchedule['tripId'] = currentStop.tripId;
                    }
                }

                if (_that.isCorrectDirection(matchedSchedule) &&
                    _that.isTripMatchTime(matchedSchedule['startTime'], timeOfService)) {
                    
                    results.push(matchedSchedule);
                }
            }

        }

        return results;
    }

};