import {default as NYTrip1} from './../data/schedules/ny/trip-ny1.json';
import {default as NYTrip2} from './../data/schedules/ny/trip-ny2.json';
import {default as NYTrip3} from './../data/schedules/ny/trip-ny3.json';
import {default as NYTrip4} from './../data/schedules/ny/trip-ny4.json';
import {default as NYTrip5} from './../data/schedules/ny/trip-ny5.json';

import {default as NJTrip1} from './../data/schedules/nj/trip-nj1.json';
import {default as NJTrip2} from './../data/schedules/nj/trip-nj2.json';
import {default as NJTrip3} from './../data/schedules/nj/trip-nj3.json';

export default {
    NYTrip1,
    NYTrip2,
    NYTrip3,
    NYTrip4,
    NYTrip5,

    NJTrip1,
    NJTrip2,
    NJTrip3
};