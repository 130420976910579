import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer className="App-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-1 center-block">
                            <div className="copyright">
                                Copyright &copy; 2019 <strong>{/*<a className="App-link" href="https://monkview.com" target="_blank" rel="noopener noreferrer">MonkView Consulting</a>*/}www.nj2nyc.com</strong>. All Rights Reserved.&nbsp;
                    <i>Disclaimer:</i> This is not an official site of any commuter agencies. However, every attempt has been made to provide accurate results based on publicly available data.&nbsp; 
                    Please feel free to <strong><a className="App-link" href="/support.html" target="_contact">contact us</a> </strong> on how we can improve. Refer to <strong><a className="App-link" href="/privacy.html" target="_privacy">Privacy Policy</a></strong> for additional details.
                </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;