import React, { Component } from 'react';
import Moment from 'react-moment';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle,faCircle,faMapMarkerAlt, faArrowCircleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import StationsModel from './../../data/stations.json';

class Schedule extends Component {
    constructor() {
        super();
        library.add(faMapMarkerAlt);
        library.add(faDotCircle);
        library.add(faCircle);
        library.add(faEllipsisV);
        library.add(faArrowCircleRight);
    }
    getStationName = (stationCode) =>{
        const stations = StationsModel;
        let st = stations.filter((s) => ((stationCode === s.value)));
        return st[0].label;
    }

    getExpCss = (express) => {
        return express && 'exp-color';
    }

    createCard = (schedule) => {
        const containerCss = 'container-fluid shadow p-3 mb-2 bg-light rounded schedule-card ' + 
                              this.getExpCss(schedule.express);
        const watermark = schedule.routeDetail && schedule.routeDetail.shortName;

        return (
            <div className={containerCss} data-watermark={watermark}>
                <div className="row">
                    <div className="col-xs-3 time-col">
                        <div className="align-top">
                            <Moment parse="HH:mm" format="hh:mm a">{schedule.startTime}</Moment>
                        </div>
                        <div className="align-bottom">
                            <Moment parse="HH:mm" format="hh:mm a">{schedule.stopTime}</Moment>
                        </div>
                    </div>
                    <div className="col-xs-3 center-col">
                        <div>
                            <FontAwesomeIcon icon="dot-circle" />
                        </div>
                        <div>
                            <FontAwesomeIcon icon="ellipsis-v" />
                        </div>
                        <div>
                            <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                    </div>
                    <div className="col-xs-3 station-col">
                        <div className="align-top">
                        {this.getStationName(schedule.fromStationCode)}
                        </div>
                        <div className="align-bottom">
                            {this.getStationName(schedule.toStationCode)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const schedule = this.props;
        return (
            <div className="schedule-container">
                {this.createCard(schedule)}
            </div>
        );
    }
}
export default Schedule;