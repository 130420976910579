import React, { Component } from 'react';
import Select from 'react-select';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowsAltH, faDotCircle, faCircle, faCalendarDay, faMapMarkerAlt,faClock } from '@fortawesome/free-solid-svg-icons';

import SearchService from './../../util/SearchService';

class Search extends Component {
    state = {
        StationsByCity : SearchService.getStationModel()
    }
    constructor() {
        super();
        library.add(faClock);
        library.add(faArrowsAltH);
        library.add(faMapMarkerAlt);
        library.add(faCalendarDay);
        library.add(faDotCircle);
        library.add(faCircle);
        library.add(faSearch);
    }
    componentDidMount() {
        let stations = SearchService.getStationModel();

        stations = stations.filter((s) => {
            return (!s.hidden);
        });

        stations.sort((a,b) => {
            let x = a.cityName.toLowerCase();
            let y = b.cityName.toLowerCase();
            if (x === y) {
                 let k = a.label.toLowerCase();
                 let l = b.label.toLowerCase();
                 return k > l ? 1 : -1;
            }else{
                return x > y ? 1 : -1;
            }
              
        });
        
        let city = [{ label : stations[0].cityName, options :[stations[0]]}];
        let cityIndex = 0;
        for (let i=1; i < stations.length; i++){
            let current = stations[i];
            let prev = stations[i-1];          
            if (current.cityCode === prev.cityCode){
                city[cityIndex].label = current.cityName;
                city[cityIndex].options.push(current);
            }else{
                city[++cityIndex] = {label :current.cityName, options :[current]};
            }
        }
        
        this.setState({StationsByCity : city});
    }
    render() {
        const { fromStation, toStation, dayOfService,timeOfService } = this.props.state;
        const stations = this.state.StationsByCity;
        const dosOptions = SearchService.getDaysOfServiceModel();
        const timeOptions = SearchService.getTimeOfServiceModel();

        return (
            <div className="container-fluid search-container">
                <form className="form-inline">
                    <div className="form-group">
                        <span className="hidden-xs"><FontAwesomeIcon icon="dot-circle" /></span>
                        <Select
                            className="form-select-station"
                            name="fromStation"
                            value={fromStation}
                            onChange={this.props.handleChange('fromStation')}
                            options={stations}
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="map-marker-alt" />
                        <Select
                            className="form-select-station"
                            name="toStation"
                            value={toStation}
                            onChange={this.props.handleChange('toStation')}
                            options={stations}
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="calendar-day" />
                        <Select
                            className="form-select-service"
                            name="dayOfService"
                            value={dayOfService}
                            onChange={this.props.handleChange('dayOfService')}
                            options={dosOptions}
                        />
                    </div>
                    <div className="form-group">
                        <FontAwesomeIcon icon="clock" />
                        <Select
                            className="form-select-service"
                            name="timeOfService"
                            value={timeOfService}
                            onChange={this.props.handleChange('timeOfService')}
                            options={timeOptions}
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" onClick={this.props.handleSearch} className="btn btn-primary"><FontAwesomeIcon icon="search" /></button>
                    </div>
                </form>
            </div>
        );
    }

}
export default Search;