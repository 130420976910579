import React, { Component } from 'react';

import { UserAgentProvider, UserAgent } from '@quentin-sommer/react-useragent';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBus, faBell, faBullhorn, faGlassCheers } from '@fortawesome/free-solid-svg-icons';

class Splash extends Component {
    state = {

    }
    constructor(props) {
        super(props);
        Object.assign(this.state, this.props.state);
        library.add(faBus);
        library.add(faBell);
        library.add(faBullhorn);
        library.add(faGlassCheers);
    }
    getPlayStoreLink() {
        return (
            <div className="center-block play-link col-xs-2">
                <a href='http://play.google.com/store/apps/details?id=com.nj2nyc'><img alt='Get it on Google Play' src='playstore_en_badge_web_generic.png' /></a>
            </div>
        );
    }
    getAppStoreLink() {
        return (
            <div className="center-block appstore-link col-xs-2">
                <a href='https://itunes.apple.com/us/app/nj-to-nyc-commuter-companion/id1453415771'>
                    <div className="app-store-link" />
                </a>
            </div>
        );
    }
    getAppStoreLinkTemp() {
        return (
            <div className="center-block appstore-link-temp col-xs-2">
            </div>
        );
    }
    render() {
        const PlayStoreLink = this.getPlayStoreLink();
        const AppStoreLink = this.getAppStoreLink();
        const appNotInstalled = !this.state.nj2nyc.Device.isMobileApp;

        return (
            <div className="container-fluid splash-container">
             {appNotInstalled && ( 
                <UserAgentProvider ua={window.navigator.userAgent}>
                    <div className="row">
                        <UserAgent computer>
                            {PlayStoreLink}
                            {AppStoreLink} 
                        </UserAgent>
                        <UserAgent android>
                            {PlayStoreLink}
                        </UserAgent>
                        <UserAgent ios>
                            {AppStoreLink}
                        </UserAgent>
                    </div>
                </UserAgentProvider>
             )}
            </div>
        );
    }
}
export default Splash;